<template>
    <DefaultLayout>
        <LoginHeader :lang=lang />
        <div id="content" class="container">        
        <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-5 col-lg-4 center-block">
                <div class="well no-padding">
                    <form id="login-form" class="smart-form client-form" >
                        <input type="hidden" name="lang" id="lang" value="#rc.lang#">
                        <header>
                            {{ labels.headerText }}
                        </header>
                        <fieldset>        
                            <section>
                                <div v-if="this.message.length > 0" :class="{'alert': true, 'alert-danger': this.hasError, 'alert-success': !this.hasError, 'fade in': true}" >
                                    {{ this.message }}
                                </div>
                            </section>       
                            <section>                                
                                <label class="label">{{ labels.username }}</label>
                                <label class="input">                                                                       
                                    <input type="email" v-model="username"   >                                    
                                    <b class="tooltip tooltip-top-right">{{ labels.tooltipUsername }}</b>                                
                                </label>
                                <span class="input-icon"><font-awesome-icon icon="user" /></span> 
                            </section>
                            <section>
                                <label class="label">{{  labels.password }}</label>
                                <label class="input"> 
                                    <input type="password" v-model="password" required>
                                    <b class="tooltip tooltip-top-right">{{  labels.tooltipPassword }}</b> 
                                </label>
                                <span class="input-icon"><font-awesome-icon icon="lock" /></span>
                                <div class="note">
                                    <RouterLink v-if="this.lang == 'en'" :to="{ name: 'ForgotPassword', state: { lang: 'en', hasError: false, message: '' }}">
                                        {{ labels.forgotPassword }}
                                    </RouterLink>
                                    <RouterLink v-else :to="{ name: 'ForgotPassword', state: { lang: 'es', hasError: false, message: '' }}">
                                        {{ labels.forgotPassword }}
                                    </RouterLink>
                                </div>
                            </section>
                            <!--<section>
                                <input type="checkbox" id="checkbox" >
                                <label for="checkbox"><i>&nbsp;&nbsp;{{ labels.staySignedIn }}</i></label>
                            </section>-->      
                        </fieldset>
                        <footer>
                            <button  class="btn btn-primary" @click="(event) => submit(event)" >
                                 {{ labels.signIn }}
                            </button>
                        </footer>
                    </form>
                </div>
            </div>
        </div>
    </div>
    </DefaultLayout>
    
</template>

<script>
import LoginHeader from '../layouts/LoginHeader.vue'
import DefaultLayout from '../layouts/DefaultLayout.vue'
/*import {useRouter} from 'vue-router'*/
import { store } from '../stores/store.js'
import axiosSrv from '../interceptors/axiosSrv'
import axios from 'axios'
import bcrypt from 'bcryptjs'

export default {
    name: "LoginPage",    
    components: {
        LoginHeader,
        DefaultLayout
    },   
    data() {
        return {
         
            username: null,
            password: null,
            hasError: false,
            lang: '' , 
            message: '',          
            store,

            labels: {
                headerText: '',
                username: '',
                password: '',
                forgotPassword: '',
                staySignedIn: '',
                signIn: '',
                tooltipUsername: '',
                tooltipPassword: '',
            }
        }
    },
    mounted() {
        this.lang = history.state.lang;
        this.hasError = history.state.hasError;
        this.message = history.state.message;

        this.assignLabels();

       // let token =  this.getAccessToken();

      //  console.log('token');
      //  console.log(token);

        // axios.post('https://login.microsoftonline.com/texaspipe.onmicrosoft.com/oauth2/v2.0/token',  
        //   {
        //     'grant_type': 'client_credentials',
        //     'client_id': '2a4dae1d-93c3-46ad-9b0d-614913265d5e',
        //     'audience': 'api://tps-api-management',
        //     'scope': 'api://tps-api-management/.default',
        //     'client_secret': 'wT68Q~idvNFmL7r2JyQg5qGML7F7.HkkCHEzScpf'
        //   }, {
        //     headers: {
        //         'Content-Type': 'multipart/form-data',               
        //     }            
        //   }
        //     ).then((response) => {

        //             console.log('response from login');
        //             console.log(response);
        //         })
        //         .catch( (error) => {
        //             console.log(error);
        //         })
    },
    setup() {        
        // const router = useRouter();       

    },
    computed: {
        getUsername() {
            return this.username;
        },
        isValidEmail() {           

            return (/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.username));

             //return  /^\w+([-]?\w+)*@\w+([-]?\w+)*(\.\w{2,3})+$/.test(this.username);
        }
    },
    methods: {
        async getAccessToken ()  {

            let response =  await axiosSrv.get('/Sharp/getAccessToken',  null, {headers: {'Content-Type': 'application/json' }});
            return  response.data.access_token;
        },
        assignLabels: function () {
            if (this.lang == 'en') {
                this.labels.headerText = 'Sign In';
                this.labels.username = 'Email/Username';
                this.labels.password =  'Password';
                this.labels.forgotPassword =  'Forgot Password?';
                this.labels.staySignedIn = 'Stay Signed In';
                this.labels.signIn = 'Sign In';
                this.labels.tooltipUsername = ' Please enter email address/username';
                this.labels.tooltipPassword = ' Enter your password';
            }
            else {
                this.labels.headerText = 'Iniciar Sesión';
                this.labels.username = 'Correo electronico/Nombre de Usuario';
                this.labels.password =  'Contraseña';
                this.labels.forgotPassword =  'Olvidaste Contraseña';
                this.labels.staySignedIn = 'Permanecer Conectado';
                this.labels.signIn = 'Entrar';
                this.labels.tooltipUsername = ' Por favor, introduzca la dirección de correo electrónico/nombre de usuario';
                this.labels.tooltipPassword = ' Ingresa tu contraseña';
            }
        },
        submit: function(event) {
            event.preventDefault();

            if (!this.isValidEmail)
            {
                this.hasError = true;
                this.message = this.lang == 'en' ? "Invalid username or password" : "Usuario o contrase&ntilde;a invalido";
                return;
            }
            //   { headers: { "Sec-Fetch-Site": "Testing", "Origin": "Thanh", "MyData": "Test" } }

            console.log('about to validate user');

          
            axios.post('/sharpUser/getUser', { username: this.username })
           /* axios.post('/proxyapi/postApi', { path: "SharpUser/getUser", username: this.username })*/
                .then((response) => {

                    //console.log('success');
                    //console.log(response);

                    let user = response.data;

                    console.log('user');
                    console.log(user);

                    if (user == null) {
                        this.hasError = true;
                        this.message = this.lang == 'en' ? "Invalid username or password" : "Usuario o contrase&ntilde;a invalido";
                        return;
                    }
                       // check user's password.
                    bcrypt.compare (this.password, user.password, (err, data) => {
                        if (err) {
                            this.hasError = true;
                            this.message = err;
                            return;
                        }
                        if (data) {
                            this.updateUserInStore(user);
                            this.$router.push('/sharpEntry?lang=' + this.lang);
                        }
                        else {
                            this.hasError = true;
                            this.message = this.lang == 'en' ? "Invalid username or password" : "Usuario o contrase&ntilde;a invalido";
                        }
                    })
                    return;

                }).catch(error => {
                      this.hasError = true;
                      this.message = error;   
                });
       
           
            //axiosSrv.post('SharpUser/getUser', { "username": this.username })            
            //    .then((response) => {
       
            //       let user = response.data;

            //        bcrypt.compare (this.password, user.password, (err, data) => {
                            
            //            console.log('data');
            //            console.log(data);
            //            console.log(err);

            //            if (err) {
            //                this.hasError = true;
            //                this.message = err;
            //                return;
            //            }
            //            if (data) {
            //                this.updateUserInStore(user);
            //                this.$router.push('/sharpEntry?lang=' + this.lang);
            //            }
            //            else {
            //                this.hasError = true;
            //                this.message = this.lang == 'en' ? "Invalid username or password" : "Usuario o contrase&ntilde;a invalido";
            //            }
            //        })
            //    })
            //    .catch ( (error) => {
            //        this.hasError = true;  
            //        this.message = error;                  
            //    })    
        },

        updateUserInStore: function (user) {

            console.log('user passed to store');
            console.log(user);


            store.isAuthenticated = true;
            store.username = user.userName;
            store.firstName = user.firstName;
            store.lastName = user.lastName;
            store.employeeNumber = user.employeeNumber;
            store.company = user.company_code;
            store.supervisorEmployeeNumber = user.sup_emp_number;
            store.supervisorFirstName = user.sup_emp_firstname;
            store.supervisorLastName = user.sup_emp_lastname;
            store.isApprover = user.isApprover;

            //console.log('store data -- saving');
            //console.log(store);

        }

    }

}
</script>

<!-- <style scoped>
h1 {
    color: orange;
}

.input-icon {
    float: right;
    margin-right: 6px;
    margin-top: -28px;
    position: relative;
    z-index: 2;
    color: black;   
}
.input-icon teal {
    color: teal;
}

.valid {
    background: url('ok.svg') no-repeat right !important;
    background-size: auto 100% !important;
 
}
.invalid {
    background: url('bad.svg') no-repeat right !important;
    background-size: auto 100% !important; 
}
.error-msg {
    background-color: red;
    color: white;
    padding: 5px;
    text-align: center;
}
</style>
 -->


