<template>
    <DefaultLayout>
        <DefaultHeader :showHeader=true :isApprover="this.isApprover" :language="this.lang" />
        <div id="content" class="container">
            <div v-if="this.showForm == true" class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 center-block" id="step2">
                    <div class="well no-padding">
                        <form id="sharpForm" class="smart-form client-form" novalidate="novalidate">
                            <header>
                              {{ labels.headerText }}
                            </header>
                            <fieldset id="sharpFormFieldSet">
                                <legend>
                                    Welcome <strong>
                                        <span id="employeeName">{{store.firstName}} {{store.lastName}}</span>
                                    </strong>!
                                </legend>
                                <section>
                                    <div v-if="this.error.length > 0" class="error-msg fade in">
                                        {{ this.error }}
                                    </div>
                                </section>
                                <section>
                                    <label class="label">{{ labels.supervisor}} </label>
                                    <label class="input">
                                        <input class="form-control" v-model="supervisor" type="text" readonly>                                        
                                    </label>
                                </section>
                                <section>
                                    <label class="label">{{ labels.branch }}<span class="tps-danger">  *</span></label>
                                    <label class="select">
                                        <select class="form-control" v-model="selWhsCode" @change="onChangeBranch">
                                            <option v-for="item in this.branches" :value="item.whsCode" :key="item.whsCode">
                                                {{ item.branchName }}
                                            </option>
                                        </select>
                                        <span class="alert-danger" v-if="v$.selWhsCode.$error"> {{  v$.selWhsCode.$errors[0].$message }}</span>
                                        <span class="help-block"></span>
                                    </label>
                                </section>
                                <section>
                                    <label class="label">{{  labels.department }}<span class="tps-danger">  *</span></label>
                                    <label class="select">
                                        <select class="form-control" v-model="selBranchLocationId">
                                            <option v-for="item in this.branchLocations" :value="item.id" :key="item.id">
                                                {{ item.Name }}
                                            </option>
                                        </select>
                                        <span class="alert-danger" v-if="v$.selBranchLocationId.$error"> {{  v$.selBranchLocationId.$errors[0].$message }}</span>
                                        <span class="help-block"></span>
                                    </label>
                                </section>
                                <section>
                                    <label class="input">{{ labels.area }}</label>
                                    <label class="input">
                                        <input class="form-control" v-model="area" type="text">
                                        <span class="help-block"></span>
                                    </label>
                                </section>
                                <section>
                                    <label class="input">{{ labels.equipment }}</label>
                                    <label class="input">
                                        <input class="form-control" v-model="equipment" type="text">
                                        <span class="help-block"></span>
                                    </label>
                                </section>
                                <section>
                                    <label class="input">{{ labels.dateOfEvent }}<span class="tps-danger">  *</span></label>
                                    <label class="input">
                                        <input class="form-control" type="date" v-model="dateSubmitted">
                                        <span class="alert-danger" v-if="v$.dateSubmitted.$error"> {{  v$.dateSubmitted.$errors[0].$message }}</span>
                                        <span class="help-block"></span>
                                    </label>
                                </section>
                                <section>
                                    <label class="input">{{ labels.timeOfEvent }}<span class="tps-danger">  *</span></label>
                                    <label class="input">
                                        <input class="form-control" type="time" v-model="timeSubmitted">
                                        <span class="alert-danger" v-if="v$.timeSubmitted.$error"> {{  v$.timeSubmitted.$errors[0].$message }}</span>
                                        <span class="help-block"></span>
                                    </label>
                                </section>
                                <section>
                                    <label class="label">{{ labels.category }}<span class="tps-danger">  *</span></label>
                                    <label class="select">
                                        <select class="form-control" v-model="selCategoryId" @change="onChangeCategory" data-msg-required="Please select what you saw.">
                                            <option v-for="item in this.categories" :value="item.id" :key="item.id">
                                                {{ item.categoryName }}
                                            </option>
                                        </select>
                                        <span class="alert-danger" v-if="v$.selCategoryId.$error"> {{  v$.selCategoryId.$errors[0].$message }}</span>
                                        <span class="help-block"></span>
                                    </label>
                                </section>
                                <section>
                                    <label class="input">{{ labels.categoryDesc }}</label>
                                    <label class="input">                                      
                                        <textarea type="text" v-model="categoryDesc" rows="3" class="form-control" readonly></textarea>
                                        <span class="help-block"></span>
                                    </label>
                                </section>
                                <section>
                                    <label class="textarea">{{ labels.desc }}<span class="tps-danger">  *</span></label>
                                    <label class="textarea">
                                        <textarea type="text" v-model="desc" rows="3" class="form-control"></textarea>
                                        <span class="alert-danger" v-if="v$.desc.$error"> {{  v$.desc.$errors[0].$message }}</span>
                                        <span class="help-block"></span>
                                    </label>
                                </section>
                                <section>
                                    <label class="textarea">{{ labels.correctiveAction }}<span class="tps-danger">  *</span></label>
                                    <label class="textarea">
                                        <textarea type="text" v-model="correctiveAction" rows="3" class="form-control"></textarea>
                                        <span class="alert-danger" v-if="v$.correctiveAction.$error"> {{  v$.correctiveAction.$errors[0].$message }}</span>
                                        <span class="help-block"></span>
                                    </label>
                                </section>
                                <section>
                                    <label class="label">{{ labels.nearMiss }}<span class="tps-danger">  *</span></label>
                                    <label class="select">
                                        <select class="form-control" v-model="nearMiss" data-msg-required="Please select what you saw.">
                                            <option>Yes</option>
                                            <option>No</option>
                                        </select>
                                        <span class="alert-danger" v-if="v$.nearMiss.$error"> {{  v$.nearMiss.$errors[0].$message }}</span>
                                        <span class="help-block"></span>
                                    </label>
                                </section>
                                <section>
                                    <button class="btn btn-success btn-lg btn-block" @click="(event) => submit(event)">
                                        Next&nbsp;<font-awesome-icon icon="arrow-right" />
                                    </button>
                                </section>
                            </fieldset>
                            <fieldset class="loader center-block hidden" id="myLoader">
                                <div class="center-block"></div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
            <div v-if="this.showForm == false" class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 center-block" >
                    <div class="well no-padding">
                        <form class="smart-form client-form">
                            <header>
                                {{ this.headerText }}
                            </header>
                            <fieldset>
                                <section>
                                    <h1 class="font-400">Thank you.</h1>
                                </section>
                                <section>
                                    <button class="btn btn-success btn-lg btn-block btn-info"  @click="(event) => enterAnotherSHARP(event)">
                                        <span v-if="this.lang == 'en'">Enter another SITE Focus Observation</span>
                                        <span v-if="this.lang == 'es'">Ingrese otra observación de SITE Focus</span>
                                    </button>
                                    <!-- <router-link v-if="this.lang == 'en'" to="/sharpEntry?lang=en" class="btn btn-success btn-lg btn-block btn-info">Enter another SHARP</router-link>
                                    <router-link v-if="this.lang == 'es'" to="/sharpEntry?lang=es" class="btn btn-success btn-lg btn-block btn-info">Entrar en otro SHARP</router-link> -->
                                </section>
                                <section>
                                    <router-link v-if="this.lang == 'en'" to="/" class="btn btn-success btn-lg btn-block btn-info">DONE</router-link>                        
                                    <router-link v-if="this.lang == 'es'" to="/" class="btn btn-success btn-lg btn-block btn-info">TERMINADO</router-link>
                                </section>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </DefaultLayout>
</template>

<script>
import DefaultHeader from '../layouts/DefaultHeader.vue'
import DefaultLayout from '../layouts/DefaultLayout.vue'
import { store } from '../stores/store.js'
//import axiosSrv from '../interceptors/axiosSrv.js'
import useValidate from '@vuelidate/core'
import { required, helpers } from '@vuelidate/validators'
import axios from 'axios'
//import { required, email, minLength, sameAs, helpers } from '@vuelidate/validators'
//import { reactive } from 'vue'
//import {useRouter} from 'vue-router'
//import moment from 'moment'

const validIndex = (value) => value >= 0;

export default {
    name: "SharpEntry",
    components: {
        DefaultHeader,
        DefaultLayout,        
    },
    setup() {

    },
    data() {
        return { 
            v$: useValidate(),
            lang: this.$route.query.lang, 
            isApprover: false,
            supervisor: '',
            selCategoryId: -1,
            selWhsCode: '',
            selBranchLocationId: -1,
            categoryDesc: '',
            desc: '',
            correctiveAction: '',
            area: '',
            equipment:  '',
        //    dateSubmitted: new Date().toISOString().slice(0,10),
        //    timeSubmitted: moment(new Date()).format('HH:mm'),
            dateSubmitted: null,
            timeSubmitted: null,
            nearMiss:  '',
            categories: [],
            branches: [],
            branchLocations: [],
            showForm: true,
            error: '',
            store,

            labels: {
                headerText: '',
                supervisor: '',
                branch: '',
                department: '',
                area: '',
                equipment: '',
                dateOfEvent: '',
                timeOfEvent: '',
                category: '',
                categoryDesc: '',
                desc: '',
                correctiveAction: '',
                nearMiss: ''
            }
        }
    },    
    validations() {

        return {
            selCategoryId: { validIndex: helpers.withMessage("Must select an item.", validIndex) },
            selWhsCode: { required },    
            selBranchLocationId: { validIndex: helpers.withMessage("Must select an item.", validIndex) },
            desc: { required },
            correctiveAction: { required },            
            dateSubmitted: { required },
            timeSubmitted: { required },
            nearMiss: { required },        
        }
    },

    mounted() {
  
        if (this.lang == 'en') {
            this.labels.supervisor = 'Supervisor';
            this.labels.branch = 'Branch';
            this.labels.department = 'Department';
            this.labels.area = 'Area';
            this.labels.equipment = 'Equipment';
            this.labels.dateOfEvent = 'Date of Event';
            this.labels.timeOfEvent = 'Time of Event';
            this.labels.category = 'Category';
            this.labels.categoryDesc = 'Example of Reporting for this Category';
            this.labels.desc = 'Description';
            this.labels.correctiveAction = 'Corrective Action';
            this.labels.nearMiss = 'Near Miss';
            this.labels.headerText = 'S.I.T.E. Focus';
        }
        else {
            this.labels.supervisor = 'Supervisora';
            this.labels.branch = 'Rama';
            this.labels.department = 'Departamento';
            this.labels.area = 'Área';
            this.labels.equipment = 'Equipo';
            this.labels.dateOfEvent = 'Fecha del evento';
            this.labels.timeOfEvent = 'Hora del evento';
            this.labels.category = 'Categoría';
            this.labels.example = 'Ejemplo de informe para esta categoría';
            this.labels.desc = 'Descripción';
            this.labels.correctiveAction = 'Acción correctiva';
            this.labels.nearMiss = 'Casi fallar';
            this.labels.headerText = 'Enfoque del SITIO';
        }
        console.log('store data');
        console.log(store);

        this.isApprover = store.isApprover;

        console.log('is approver?');
        console.log(this.isApprover);

        this.supervisor = store.supervisorFirstName + ' ' + store.supervisorLastName;
        this.getCategories();
        this.getAllBranches();   
        this.getLocationsByBranch();
    },

    methods: {

        async getCategories() {

            //let token = await this.getAccessToken();
            // let config = {
            //     headers: {
            //         'Authorization': 'Bearer ' + token,
            //         'Ocp-Apim-Subscription-Key': '910fceddd2114649827c0b961af42c39',
            //         // 'Access-Control-Allow-Origin': '*',
            //         // 'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
                    // 'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS'
            //    }
            //}
            // axiosSrv.get('https://apim-tps-qa-scus.azure-api.net/TpsApi/SharpCategory/getByLanguage?language=' + this.lang, config)
            //     .then((response) => {
            //         this.categories = response.data;
            //     });


            axios.post('/sharpCategory/getByLanguage', { language: this.lang })
                .then((response) => {
                    this.categories = response.data;
                });

        //    axiosSrv.get('SharpCategory/getByLanguage?language=' + this.lang)
        //    .then((response) => {
        //        this.categories = response.data;
        //    });
        },

        //async getAccessToken ()  {

        //    let response =  await axiosSrv.get('/Sharp/getAccessToken',  {headers: { '0Content-Type': 'application/json'} } );
        //    return  response.data.access_token;
        //},
        
        async getAllBranches() {

            axios.post('/sharpBranch/getAllBranches')
                .then((response) => {
                    this.branches = response.data;
                });

        ////    axiosSrv.get('SharpBranch/getAllBranches')
        ////    .then((response) => {
        ////        this.branches = response.data;
        ////    });
        },

        async getLocationsByBranch() {

            //axios.post('/proxyapi/postApi', { path: "TpsApi/SharpBranch/getLocationsByBranch", whsCode: this.selWhsCode })

            axios.post('/sharpBranch/getLocationsByBranch', { whsCode: this.selWhsCode })
                .then((response) => {
                    this.branchLocations = response.data;
                });

        ////    axiosSrv.post('SharpBranch/getLocationsByBranch', { "whsCode": this.selWhsCode })
        ////    .then((response) => {
        ////        this.branchLocations = response.data;
        ////    });
        },

        onChangeCategory () { 
            var item = this.categories.find( x=> x.id == this.selCategoryId);
            this.categoryDesc = item.description;        
        },

        onChangeBranch () { 
            this.getLocationsByBranch();
        },

        async submit(event) {

            event.preventDefault();

            this.v$.$validate();            
                 
            if (this.v$.$error) {
                return; 
            }
            this.error = '';


            var model = {
                language: this.lang,
                employeeNumber: store.employeeNumber, employeeFirstName: store.firstName, employeeLastName: store.lastName,
                supervisorEmployeeNumber: store.supervisorEmployeeNumber, supervisorFirstName: store.supervisorFirstName, supervisorLastName: store.supervisorLastName,
                name: this.name, description: this.desc, whsCode: this.selWhsCode, dateSubmitted: this.dateSubmitted, dateSubmitted1: this.dateSubmitted,
                timeSubmitted: this.timeSubmitted, company: store.company, isApproved: true, reasonID: this.selCategoryId,
                branchLocationid: this.selBranchLocationId, correctiveAction: this.correctiveAction, area: this.area, equipment: this.equipment, nearMiss: this.nearMiss
            }

            console.log('entry model');
            console.log(model);


            try {
                await axios.post('/sharpEntries/create', model);

            
                if (this.nearMiss == 'Yes') {

                    var loc = this.branchLocations.find(x => x.id == this.selBranchLocationId);
                    var branch = this.branches.find(x => x.whsCode == this.selWhsCode);

                     
                    var incidentModel = {
                        language: this.lang, Date: this.dateSubmitted, Company: branch.branchName, Location: loc.Name, 
                        Description: '(blanks)', CorrectiveAction: this.correctiveAction, Comments: this.desc, Analysis: 'Near Miss'
                    }
                    //console.log('incident model');
                    //console.log(incidentModel);

                    //var response2 = await axios.post('/proxyapi/postApi', incidentModel);

                    var response2 = await axios.post('/ehs/CreateIncident', incidentModel);

                    if (response2.data) {
                        alert('SharePoint Incident was created.');
                    }
                    else {
                        alert('Could not create SharePoint Incident.  Please contact administrator!');
                    }
                }
                this.showForm = false;
            }
            catch (error) {
                this.showForm = true;
                this.error = error;
            }


        },

        enterAnotherSHARP() {

            this.showForm = true;

            this.clearFields();
            
        },

        clearFields () {

          //  this.selBranchLocationId =  '';
            this.selCategoryId = -1;
            this.categoryDesc = '';
            this.name = '';
            this.desc = '';
            this.correctiveAction = '';
            this.area = '';
            this.equipment = '';
            this.nearMiss = '';

        }
    },
}
</script>   