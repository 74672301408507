import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
import LoginPage from './components/LoginPage.vue';
import SharpEntry from './components/SharpEntry.vue';
import SiteFocusView from './components/SiteFocusView.vue';
import RegisterPage from './components/RegisterPage.vue';
import Register2Page from './components/Register2Page.vue';
import ThanksPage from './components/ThanksPage.vue';
import ForgotPassword from './components/ForgotPassword.vue';
import ForgotPassword2 from './components/ForgotPassword2.vue';
import NotFound from './components/NotFound.vue'
import { store } from './stores/store.js'

const routes = [
    {
        path: "/",
        name: "Home",
        component: HomePage,
    },
    //{
    //    path: "/notfound",
    //    name: "NotFound",
    //    component: NotFound,
        
    //},
    {
        path: "/login",
        name: "Login",
        component: LoginPage,
        //beforeEnter: (to, from, next) => {
        //    next();
        //}
    },
    {
        path: '/sharpEntry',
        name: "SharpEntry",
        component: SharpEntry,
    },
    {
        path: '/siteFocusView',
        name: "SiteFocusView",
        component: SiteFocusView,
    },
    {
        path: '/register',
        name: "Register",
        component: RegisterPage,
    },
    {
        path: '/register2',
        name: "Register2",
        component: Register2Page,
    },
    {
        path: '/forgotPassword',
        name: "ForgotPassword",
        component: ForgotPassword,
    },
    {
        path: '/forgotPassword2',
        name: "ForgotPassword2",
        component: ForgotPassword2,
    },
    {
        path: '/thanks',
        name: "Thanks",
        component: ThanksPage,
        props: true,
        //beforeEnter: (to, from, next) => {
        //    next();
        //}
    },
    {
        path: "/:catchAll(.*)",
        component: NotFound,
    }
   
]

const router = createRouter({ history: createWebHistory(), routes })

router.beforeEach((to, from, next) => {

    if (to.name == 'Home' || to.name == "Login" || to.name == "Register2" || to.name == "ForgotPassword2") {
        next()
    }
    else if (store.isAuthenticated) {
        console.log('authenticated')
        next()
    }
    else {
        console.log('not authenticated');
     //   next({ name: 'Home' })

        next();
    }
})

export default router;